@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media only screen and (max-width: 46em) { @content }; // 750px
    }
    @if $breakpoint == phone-land {
        @media only screen and (max-width: 900px) and (max-height: 500px){ @content }; // 750px
    }
    @if $breakpoint == tab-port {
        @media only screen and (max-width: 56.25em) { @content }; // 900px 56.25em
    }
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 75em) { @content }; // 1200px
    }
    @if $breakpoint == mac {
        @media only screen and (max-width: 80em) { @content }; // 1280px
    }
    @if $breakpoint == laptop {
        @media only screen and (max-width: 90em) { @content }; // 1366px
    }
    @if $breakpoint == desktop125 {
        @media only screen and (max-width: 96em) { @content }; // 1366px
    }
    @if $breakpoint == big-desktop {
        @media only screen and (max-width: 112.5em) { @content }; // 1800px
    }
    @if $breakpoint == all-desktop {
        @media only screen and (min-width: 85em) { @content }; //1360px+
    }
    @if $breakpoint == landscape-mobile {
        @media only screen and (orientation: landscape) { @content }; //1360px+
    }
}


body {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  background-color:#FFF0DE!important;
  overflow-x: hidden;
}

.frubik {
  font-family: 'Rubik', sans-serif;
}

.red-text {
  color: #FF5E48;
}

.blue-text {
  color: #3F8FFE;
}

.red-bg {
  background: #FF5E48;
}

.blue-bg {
  background: #3F8FFE;
}

.header {
  min-height: 100vh;
  background-size: cover;
  position: relative;
  padding-left: 10vw;
  padding-right: 5vw;
  padding-top: 5vh;
  padding-top: 5vh;

  @include respond(desktop125) {
    padding-left: 7vw;
    padding-right: 7vw;
  }

  @include respond(tab-port) {
    padding-left: 3vw;
    padding-right: 3vw;
    padding-top: 3vh;
    padding-bottom: 20px!important;
  }

  &__text {
    max-width: 100%;
    width: 660px;
    margin-top: 50px;

    @include respond(tab-land) {
      width: 400px;
    }
    @include respond(tab-port) {
      width: 100%;
    }

    h1 {
      font-size: 120px;
      line-height: .6;
      font-weight: bold;

      @include respond(desktop125) {
        font-size: 80px;
      }
      @include respond(tab-land) {
        font-size: 60px;
      }

      @include respond(tab-port) {
        font-size: 40px;
      }
    }

    p {
      font-size: 28px;
      @include respond(desktop125) {
        font-size: 20px;
      }

      @include respond(tab-port) {
        font-size: 14px;
      }
    }
  }

  &__line {
    width: 276px;
    height: 5px;
    margin-top: 50px;
    @include respond(desktop125) {
      margin-top: 40px;
      margin-bottom: 10px;
    }

    @include respond(tab-port) {
      margin-top: 25px;
      margin-bottom: 10px;
      width: 180px;
    }
  }


  &__image {
    position: absolute;
    top: 50px;
    right: 0px;
    @include respond(desktop125) {
      right: 50px;
    }

    @include respond(laptop) {
      right: 10px;
    }

    @include respond(tab-land) {
      right: -100px;
    }

    @include respond(tab-port) {
      position: relative;
      right: initial;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      top: 0;
    }

    img {
      max-height: 95vh;

      @include respond(desktop125) {
        max-height: 85vh;
      }

      @include respond(tab-port) {
        max-height: 50vh;
        margin-bottom: 10px;
      }

       @include respond(phone) {
        max-height: 30vh;
        margin-bottom: 10px;
      }
    }
  }

  &__button {
    width: 200px;
    height: 50px;
    color: white;
    font-weight: bold;
    outline: 0;
    border: 0;
    transition: .5s ease-in-out all;
    &:hover {
      opacity: .7;
    }
  }

  &__triangle {
    height: 70px;
    position: relative;
    left: -30px;
  }

  .scroll {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    img {
      @include respond(phone) {
        max-width: 50px;
      }
    }

  }

  .social-hero {
    bottom: 10px;
    right: 0;

    @include respond(tab-port) {
      display: none;
    }
  }

  .social-img {
    align-items: flex-end;

    img {
      transform: scale(.8);
      transform-origin: left bottom;
    }
  }
}

.main-menu {
  position: relative;
    top: 17px;

    img {
      @include respond(desktop125) {
      max-width: 150px;
    }
    }

  @include respond(tab-port) {


  }

  // @include respond(phone) {
  //   max-width: 50px;
  //   top: 10px;
  // }

  img {
    @include respond(tab-port) {
      max-width: 70px;
    }
  }
}

.logo {
  img {

    @include respond(desktop125) {
      max-width: 200px;
    }

    @include respond(tab-port) {
      max-width: 100px;
    }
  }
}

// Sidebar

.close-menu {

  @include respond(desktop125) {
  max-width: 70px!important;
  }

  @include respond(tab-port) {
    max-width: 35px!important;
  }
}

.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
  background: black;
  transition: 0.2s ease-in-out all;
  padding-left: 10vw;
  padding-right: 5vw;
  padding-top: 5vh;
  padding-top: 5vh;
  overflow: hidden;

  @include respond(desktop125) {
    padding-left: 7vw;
    padding-right: 7vw;
  }

   @include respond(tab-port) {
    padding-left: 3vw;
    padding-right: 3vw;
    padding-top: 3vh;
    padding-top: 3vh;
  }

  @include respond(phone) {
    padding-left: 3vw;
  }

  &__menu {
    position: absolute;
  }

  &__left {
    position: absolute;
    left:-50px;
    max-height: 65vh;
    bottom: 0;

    @include respond(desktop125) {
      left: -30px;
    }

     @include respond(tab-port) {
      display: none;
    }
  }

  &__right {
    position: absolute;
    right:-230px;
    max-height: 65vh;
    bottom: 0;

    @include respond(desktop125) {
      right: -160px;
    }

    @include respond(tab-port) {
      display: none;
    }
  }

  &__social {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;

    @include respond(desktop125) {
      bottom: 25px;
    }

    @include respond(desktop125) {
      bottom: 45px;
    }


  }

  &__menu {
    position: absolute;
    left: 50%;
    top: 200px;
    transform: translateX(-50%);

    @include respond(desktop125) {
      top: 120px;
    }

    @include respond(tab-port) {
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    li {
      list-style: none;
      text-align: center;
      a {
        font-size: 40px;
        color: #E33F44;
        font-weight: bold;

        @include respond(desktop125) {
          font-size: 30px;
        }

         @include respond(tab-port) {
          font-size: 24px;
        }
      }
    }
  }
}

.sidebar-show {
  z-index: 222;
  opacity: 1;
  height: 100%;
  overflow-y: hidden;
  transition: .5s ease-in-out all;
}

.body-sidebar {
  height: 100vh;
  overflow-y: hidden;
}

::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}


// About

.about {
  background-size: cover;
  min-height: 780px;

  @include respond(phone) {
    min-height: 700px;
  }

  &__textbg {
    max-width: 1300px;
    display: block;
    margin: 0 auto;
    height: 450px;

    @include respond(laptop) {
      max-width: 1100px;
    }

    @include respond(tab-land) {
      max-width: 900px;
      height: 400px;
    }

    @include respond(tab-port) {
      max-width: 95%;
    }

  }

  &__content {
    color: white;
    font-size: 32px;
    top: 0;
    padding: 3%;

    @include respond(laptop) {
      font-size: 24px;
    }

    @include respond(tab-land) {
      font-size: 20px;
    }

    @include respond(tab-port) {
      font-size: 16px;
      padding: 5%;
    }

  }

  &__container {
    width: 1300px;
    max-width: 100%;
    display: block;
    margin: 0 auto;

    @include respond(laptop) {
      width: 1100px;
    }

    @include respond(tab-land) {
      width: 900px;
    }

    @include respond(tab-port) {
      max-width: 95%;
    }
  }

  &__logo {
    margin-top: 40px;
    max-height: 60px;
  }

  &__figure {
    bottom: -30px;
    right: -10px;
    height: 300px;

    @include respond(laptop) {
      bottom: -24px;
      right: -18px;
    }

    @include respond(tab-port) {
      display: none;
    }
  }
}

// universal section elements

.section {
  background-size: cover;

  &__container {
    max-width: 90%;
    width: 1400px;
    display: block;
    margin: 0 auto;
    position: relative;
    top: -50px;
    @include respond(laptop) {
      width: 1200px!important;
    }
  }

  &__avatar {
    @include respond(tab-land) {
      max-width: 350px;
    }
    @include respond(tab-port) {
      display: block;
      margin: 0 auto;
      max-width: 250px;
    }
  }

&__under {
  left: -350px;
  bottom: -50px;
  @include respond(laptop) {
    left: -450px;
  }

  @include respond(tab-land) {
    left: -630px;
  }
  @include respond(tab-port) {
    display: none;
  }
}

&__under--blue {
  right: -350px;
  bottom: -50px;
  @include respond(laptop) {
    right: -450px;
  }

  @include respond(tab-land) {
    right: -630px;
  }
  @include respond(tab-port) {
    display: none;
  }
}

&__redmargine {
  margin-right: 204px;
  @include respond(laptop) {
    margin-right: 100px;
  }
  @include respond(tab-port) {
    margin-right: 0;
  }
}

&__bluemargine {
  margin-left: 204px;
  @include respond(laptop) {
    margin-left: 100px;
  }
  @include respond(tab-port) {
    margin-left: 0;
  }
}

  &__title {
    h2 {
      font-size: 78px;
      line-height: 1;
      font-weight: 900;
      @include respond(desktop125) {
        font-size: 78px;
      }
      @include respond(laptop) {
        font-size: 68px;
      }
      @include respond(tab-land) {
        font-size: 60px;
      }
      @include respond(tab-port) {
        font-size: 30px;
        margin: 0 auto;
      }
      @include respond(phone) {
        text-align: center;
      }
    }
  }

  &__figure {
    margin-left: auto;
    img {
      @include respond(desktop125) {
      max-height: 180px;
    }
    @include respond(tab-land) {
      max-height: 120px;
    }
    @include respond(tab-port) {
      display: none;
    }
  }
}

  &__description {
    font-size: 18px;
    font-weight: bold;
    margin-top: 40px;

    @include respond(tab-land) {
      margin-top: 20px;
    }

    @include respond(tab-port) {
      font-size: 16px;
    }

     @include respond(phone) {
      text-align: center;
    }
  }

  &__button {
    display: inline;
    z-index: 13;

    @include respond(tab-port) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    button {
      display: inline;
      justify-content: center;
      align-items: center;
      outline: 0;
      border: 0;
      width: 296px;
      font-size: 24px;
      height: 60px;
      color: white;
      font-weight: bold;
      border-radius: 30px;
      cursor: pointer;
      z-index: 13;

      @include respond(tab-land) {
        height: 48px;
        font-size: 20px;
        width: 220px;
      }
    }
  }

  &__triangle-red {
    right: -70px;

    @include respond(tab-land) {
      max-height: 70px;
      right: -50px;
    }

    @include respond(tab-port) {
      display: none;
    }
  }

  &__triangle-blue {
    right: -55px;
    top: 0px;

    @include respond(tab-land) {
      max-height: 70px;
      right: -50px;
    }

    @include respond(tab-port) {
      display: none;
    }
  }

  &__under-red {
    left: -250px;
    top: -160px;
    z-index: -1;

    @include respond(laptop) {
      width: 200px;
      left: -150px;
      top: -110px;
    }

    @include respond(tab-port) {
      display: none;
    }
  }

  &__under-blue {
    top: -50px;
    z-index: -1;

    @include respond(desktop125) {
      width: 597px;
    }

     @include respond(laptop) {
      width: 430px;
    }

    @include respond(tab-land) {
      width: 400px;
    }

    @include respond(tab-port) {
      display: none;
    }
  }
}
// section 1

.section.sectionone {
    margin-top: -309px;
    padding-top: 300px;
    height: 976px;

    @include respond(tab-port) {
      padding-top: 270px;
      height: 990px;
    }
}

.section.sectiontwo {
    margin-top: -249px;
    padding-top: 350px;
    height: 1076px;

    @include respond(tab-port) {
      padding-top: 180px;
      height: 810px;
    }
}


.section.sectionthree {
    margin-top: -229px;
    padding-top: 250px;
    height: 926px;

    @include respond(tab-port) {
      padding-top: 250px;
      height: 750px;
    }

    @include respond(phone) {
      padding-top: 280px;
      height: 1000px;
      margin-top: -239px;
    }
}

.section.sectionfour {
    margin-top: -229px;
    padding-top: 310px;
    height: 1196px;

    @include respond(tab-port) {
      padding-top: 220px;
      height: 820px;
    }

    @include respond(phone) {
      padding-top: 172px;
      height: 820px;
    }
}

.section.sectionfive {
    margin-top: -393px;
    padding-top: 300px;
    height: 1066px;

    @include respond(tab-port) {
      margin-top: -163px;
      padding-top: 170px;
      height: 770px;
    }
}

.section.sectionsix {
    margin-top: -289px;
    padding-top: 350px;
    height: 1076px;

    @include respond(tab-port) {
      padding-top: 200px;
      height: 780px;
      margin-top: -169px;
    }
}

.section.sectionseven {
    margin-top: -229px;
    padding-top: 250px;
    height: 926px;

    @include respond(tab-port) {
      padding-top: 250px;
      height: 750px;
    }

    @include respond(phone) {
      padding-top: 280px;
      height: 1040px;
    }
}

.section.sectioneight {
    margin-top: -229px;
    padding-top: 350px;
    height: 876px;

    @include respond(tab-port) {
      padding-top: 220px;
      height: 750px;
    }
}


// footer

.footer {
  padding-top: 120px;
  margin-top: 0;
  min-height: 370px;
  max-width: 100%;
  background-size: 100% 100%!important;
  background-repeat: no-repeat;

  @include respond(phone) {
    min-height: 340px;
  }

  &__social {
    display: flex;
    flex-direction: column;
  }

  &__ministarstvo {
    img {
      display: block;
      margin: 0 auto;

      @include respond(laptop) {
        max-width: 70%;
      }
    }
  }
   &__logo {
      img {
        @include respond(laptop) {
          max-width: 75%;
        }

        @include respond(tab-port) {
          display: block;
          margin: 0 auto 20px auto;
        }
      }
    }
}



// article

.article {
  &__block {
    max-width: 960px;
    margin: 20px auto 0px auto;

    img {
      max-width: 100%;
    }
  }

  &__block {
    transition: .5s ease-in-out all;
  }

  &__circle {
    top: -80px;
    right: -80px;
    z-index: 0;

    @include respond(tab-port) {
      display: none;
    }
  }

  &__linebluefirst {
    bottom: -60px;
    left: -40px;

    @include respond(tab-port) {
      display: none;
    }
  }

  &__title {
    margin-top: 60px;
    display: flex;

    h1 {
      font-size: 46px;
      display: inline;
      margin: 0 auto;
      padding: 10px 30px;
      font-weight: bold;
    }

    &--lineblue {
      width:59px;
      height: 7px;
      background-color: #3F8FFE;
      top: 50%;
      left: -35px;
    }

    &--linered {
      width:59px;
      height: 7px;
      background-color: #FF5E48;
      top: 50%;
      left: -35px;
    }
  }

.border-red {
  border: 2px solid #FF5E48;
  border-radius: 30px;
}

.border-blue {
  border: 2px solid #3F8FFE;
  border-radius: 30px;
}

  &__box {
    margin-top: 100px;
    transform: rotate(-2deg);
    background-color: #FEE7CD;
    overflow: hidden;

    @include respond(tab-port) {
      margin-top: 20px;
    transform: rotate(0deg);

    }
  }

  &__wrap {
    transform: rotate(2deg);
    padding: 0 20px;

    @include respond(tab-port) {
    transform: rotate(0deg);

    }
  }

  .red-box-border {
    border: 2px solid #FF5E48;
  }

 p {
  font-size: 22px;

  @include respond(phone) {
    font-size: 16px;
  }
 }

&__figureone {
  left: 0;
  bottom: 0;

  @include respond(desktop125) {
    max-width: 299px;
  }

  @include respond(laptop) {
    max-width: 150px;
  }

  @include respond(tab-port) {
    display: none!important;
  }
}

&__figuretwo {
  right: -50px;
  bottom: 0;

  @include respond(desktop125) {
    max-width: 299px;
  }

  @include respond(laptop) {
    max-width: 150px;
  }

  @include respond(tab-port) {
    display: none!important;
  }
}


}

.article__linebluesecond {
  position: absolute;
  z-index: 0;
  bottom: 73px;
  margin-right: 10px;
  transform: translateX(-30px);

  @include respond(tab-port) {
    display: none;
  }
}


.video-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    background: black;
    overflow-y: none;
    z-index: 1000;
    transition: .5s ease-in-out all;


    .close-modal {
      position: absolute;
      top: 50px;
      right: 100px;
      width: 50px;
      cursor: pointer;

      img {
        max-width: 100%;
      }

      @include respond(tab-port) {
        right: 20px;
        top: 50px;
      }
    }
}

.blue-bg a,
.red-bg a {
  color: white;
  text-decoration: none;
  transition: .5s ease-in-out all;

  &:hover {
    color: white;
  }
}

.section__figure {
  img {
    max-height: 162px;

    @include respond(desktop125) {
       max-height: 162px;
    }

    @include respond(laptop) {
       max-height: 132px;
    }

    @include respond(tab-land) {
      max-height: 120px;
    }
  }
}

img.section__avatar.position-relative {
    z-index: 42!important;
}

ul.legacy {
list-style: none;
position: absolute;
left: 50%;
transform: translateX(-50%);
top: 0;
@include respond(phone) {
  top: 10px;
}



li {
  a {
    color: white;
    font-weight: bold;
    font-size: 20px;
    @include respond(phone) {
      font-size: 12px;
    }
  }
}
}

.tfix {
  transform: translateY(10px);
}

.playbutton {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 33;
  cursor: pointer;
  transition: .5s ease-in-out all;

  &:hover {
    transform: translate(-50%, -50%) scale(1.1);
  }

  @include respond(phone) {
    width: 75px;
  }
}